export const baseUrl = "https://api.seekinglight.cn:3000"

export const OPENAI_API_KEY = "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"

export const toolPrompt = `你是一个机械行业的专家。我现在会给你一些图片，里面会包含一些公式，你需要做下面的事情:

1. 用不超过10个字概括这些公式的作用
2. 将公式等于号左侧部分提取出来，并用一个JavaScript合法变量名作为命名
3. 将公式等于号右侧所有的变量提取出来，并把这个公式右侧转换成JavaScript的表达式（不用考虑变量的初始化）
4. 将公式用Latex表示

不要返回多余的内容，你只能返回合法的JSON！

举例

---输入
假设输入是一张图片，里面包含三个公式,实例图片在第一张
---
---输出
{
  “Title”: “受压或受拉时应力、应变计算”,
  “outputs”: [
    {
      “leftName”: “截面积”,
      “leftIdentifier”: “S”,
      “leftUnit”: “m²”,
      “jsCalculateStr”: “Math.PI * Math.pow(D, 2) / 4”,
      “rightVariables”: [
        {
          “name”: “直径”,
          “identifier”: “D”,
          “unit”: “m”
        }
      ],
      “latexString”: “S = \\frac{\\pi D^2}{4}”
    },
    {
      “leftName”: “应力”,
      “leftIdentifier”: “sigma”,
      “leftUnit”: “Pa”,
      “jsCalculateStr”: “F / S”,
      “rightVariables”: [
        {
          “name”: “力”,
          “identifier”: “F”,
          “unit”: “N”
        },
        {
          “name”: “截面积”,
          “identifier”: “S”,
          “unit”: “m²”
        }
      ],
      “latexString”: “\\sigma = \\frac{F}{S}”
    },
    {
      “leftName”: “计算变形量”,
      “leftIdentifier”: “deltaL”,
      “leftUnit”: “m”,
      “jsCalculateStr”: “(F * L) / (S * E)”,
      “rightVariables”: [
        {
          “Name”: “原始长度”,
          “identifier”: “L”,
          “unit”: “m”
        },
        {
          “Name”: “弹性模量”,
          “identifier”: “E”,
          “unit”: “Pa”
        },
        {
          “name”: “力”,
          “identifier”: “F”,
          “unit”: “N”
        },
        {
          “name”: “截面积”,
          “identifier”: “S”,
          “unit”: “m²”
        }
      ],
      “latexString”: “\\Delta L = \\frac{F \\cdot L}{S \\cdot E}”
    }
  ]
}

---
这是第一张图片的Json，接下来你需要给我返回第二张图片的Json
`;
