import React, { useRef, useState } from 'react';
import { ProTable } from '@ant-design/pro-components';
import type { ProColumns, ActionType } from '@ant-design/pro-components';
import { message, Button, Modal, Form, Input, Upload, Space, Row, Col } from 'antd';
import { InboxOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import request from "umi-request";
import { baseUrl, OPENAI_API_KEY, toolPrompt } from '../../config';
import * as qiniu from 'qiniu-js';
import * as CryptoJS from 'crypto-js';
import { getQiniuToken, QINIU_DOMAIN } from '../qiniuTool';
import axios from 'axios';


const { Dragger } = Upload;

interface Input {
  type: 'number' | 'select';
  name: string;
  unit?: string;
}

interface Output {
  name: string;
}

interface ITool {
  _id: string;
  title: string;
  formulaList: string[];
  inputs: Input[];
  outputs: Output[];
}

interface ToolResponse {
  data: ITool[];
  total: number;
  page: string;
  size: string;
}

const ToolManagement: React.FC = () => {
  const actionRef = useRef<ActionType>();
  const [total, setTotal] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [parseResult, setParseResult] = useState(null);

  const queryTools = async (params: any) => {
    try {
      const response = await request<ToolResponse>(`${baseUrl}/tools`, {
        method: 'GET',
        params,
      });
      setTotal(response.total);
      return {
        data: response.data,
        success: true,
        total: response.total,
      };
    } catch (error) {
      message.error('获取工具列表失败');
      return {
        data: [],
        success: false,
        total: 0,
      };
    }
  };

  const parseImgByLlm = async (urls: string[]) => {
    const openaiApiEndpoint = 'https://api.openai.com/v1/chat/completions';
    const model = 'gpt-4o';


    const content = [
      { type: 'text', text: toolPrompt },
      ...urls.map(url => ({ type: 'image_url', image_url: { url } }))
    ];

    const requestBody = {
      model: model,
      messages: [{ role: 'user', content }],
      max_tokens: 1000,
      response_format: { type: 'json_object' }
    };

    try {
      const response = await axios.post(openaiApiEndpoint, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`
        }
      });

      const result = response.data.choices[0].message.content;
      console.log('GPT-4 Vision 解析结果:', JSON.parse(result));
      setParseResult(JSON.parse(result));

      return JSON.parse(result);
    } catch (error) {
      message.destroy();
      message.error("调用 GPT-4o API 失败:", error);
      throw error;
    }
  };

  const uploadProps = {
    name: 'file',
    multiple: false,
    customRequest: ({ file, onProgress, onSuccess, onError }) => {
      const token = getQiniuToken();
      const observable = qiniu.upload(file as File, null, token, {}, {
        region: qiniu.region.z2
      });

      observable.subscribe({
        next: (res) => {
          onProgress?.({ percent: res.total.percent });
        },
        error: (err) => {
          onError?.(err);
          message.error(`${(file as File).name} 上传失败`);
        },
        complete: async (res) => {
          const url = `${QINIU_DOMAIN}/${res.key}`;
          setUploadedUrl(url);
          onSuccess?.(res, file as any);
          message.loading("正在解析公式...", 20000)
          const parseResult = await parseImgByLlm(['http://imgs.seekinglight.cn/FrStG9yrzTtXkpUbobgUEynM8_t9', url])
          console.log("解析结果", parseResult);

          // 设置表单字段值
          form.setFieldsValue({
            title: parseResult.Title,
            outputs: parseResult.outputs.map(output => ({
              leftName: output.leftName,
              leftIdentifier: output.leftIdentifier,
              leftUnit: output.leftUnit,
              jsCalculateStr: output.jsCalculateStr,
              rightVariables: output.rightVariables
            }))
          });

          message.destroy()
          message.success("公式解析成功！")
        }
      });
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const formattedValues = {
        ...values,
        imageUrl: uploadedUrl,
        outputs: values.outputs.map(output => ({
          ...output,
          rightVariables: output.rightVariables.filter(v => v && v.name && v.identifier)
        }))
      };
      const response = await request(`${baseUrl}/tools`, {
        method: 'POST',
        data: formattedValues,
      });
      if (response.success) {
        message.success('工具添加成功');
        setIsModalVisible(false);
        form.resetFields();
        setUploadedUrl('');
        setParseResult(null);
        actionRef.current?.reload();
      } else {
        message.error('工具添加失败');
      }
    } catch (error) {
      console.error('验证失败:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setUploadedUrl('');
    setParseResult(null);
  };

  const columns: ProColumns<ITool>[] = [
    {
      title: '标题',
      dataIndex: 'title',
      ellipsis: true,
    },
    {
      title: '公式列表',
      dataIndex: 'formulaList',
      ellipsis: true,
      render: (_, record) => (
        <ul>
          {record.formulaList.map((formula, index) => (
            <li key={index}>{formula}</li>
          ))}
        </ul>
      ),
    },
    {
      title: '输入',
      dataIndex: 'inputs',
      ellipsis: true,
      render: (_, record) => (
        <ul>
          {record.inputs.map((input, index) => (
            <li key={index}>
              {input.name} ({input.type})
              {input.type === 'number' && input.unit && ` - 单位: ${input.unit}`}
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: '输出',
      dataIndex: 'outputs',
      ellipsis: true,
      render: (_, record) => (
        <ul>
          {record.outputs.map((output, index) => (
            <li key={index}>{output.name}</li>
          ))}
        </ul>
      ),
    },
    {
      title: '操作',
      valueType: 'option',
      render: (text, record, _, action) => [
        <a
          key="edit"
          onClick={() => {
            action?.startEditable?.(record._id);
          }}
        >
          编辑
        </a>,
        <a
          key="delete"
          onClick={() => {
            // 实现删除逻辑
          }}
        >
          删除
        </a>,
      ],
    },
  ];

  return (
    <>
      <Button type="primary" onClick={showModal} style={{ marginBottom: 16, marginRight: 16, float: 'right' }}>
        添加工具
      </Button>
      <ProTable<ITool>
        columns={columns}
        actionRef={actionRef}
        cardBordered
        request={async (params = {}) => {
          const { current, pageSize, ...rest } = params;
          return queryTools({
            page: current,
            size: pageSize,
            ...rest,
          });
        }}
        editable={{
          type: 'multiple',
          onSave: async (rowKey, data, row) => {
            try {
              await request(`${baseUrl}/tools/${data._id}`, {
                method: 'PUT',
                data,
              });
              message.success('更新成功');
              actionRef.current?.reload();
            } catch (error) {
              message.error('更新失败');
            }
          },
        }}
        columnsState={{
          persistenceKey: 'pro-table-singe-demos',
          persistenceType: 'localStorage',
        }}
        rowKey="_id"
        search={{
          labelWidth: 'auto',
        }}
        pagination={{
          pageSize: 10,
          total,
        }}
        dateFormatter="string"
        headerTitle="工具管理"
      />
      <Modal
        title="添加工具"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="上传图片">
            {/* @ts-ignore */}
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
            </Dragger>
          </Form.Item>
          {uploadedUrl && (
            <Form.Item label="已上传图片">
              <img src={uploadedUrl} alt="上传的图片" style={{ maxWidth: '100%' }} />
            </Form.Item>
          )}

          {/* ------------------------图片区--------------------------- */}

          <Form.Item name="title" label="标题" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          {parseResult && parseResult.outputs && parseResult.outputs.map((output, index) => (
            <div key={index}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item name={['outputs', index, 'leftName']} label="左侧名称" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={['outputs', index, 'leftIdentifier']} label="左侧标识符" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name={['outputs', index, 'leftUnit']} label="左侧单位">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name={['outputs', index, 'jsCalculateStr']} label="JavaScript计算表达式" rules={[{ required: true }]}>
                <Input.TextArea />
              </Form.Item>

              <Form.List name={['outputs', index, 'rightVariables']}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, i) => (
                      <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                          {...field}
                          name={[field.name, 'name']}
                          fieldKey={[field.fieldKey, 'name']}
                          rules={[{ required: true, message: '请输入变量名称' }]}
                        >
                          <Input placeholder="变量名称" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'identifier']}
                          fieldKey={[field.fieldKey, 'identifier']}
                          rules={[{ required: true, message: '请输入变量标识符' }]}
                        >
                          <Input placeholder="变量标识符" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'unit']}
                          fieldKey={[field.fieldKey, 'unit']}
                        >
                          <Input placeholder="单位" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(field.name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        添加变量
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          ))}
        </Form>
      </Modal>
    </>
  );
};

export default ToolManagement;